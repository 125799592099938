<template>
  <div class="empty-pages">
    <div class="empty-box">
      <img class="image-logo" alt="logo" src="../assets/logo.png" />
      <span class="empty-text">敬请期待...</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "empty-pages",
  components: {},
};
</script>
<style lang="scss" spcoded>
.empty-pages {
  padding-top: 120px;
  .empty-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    padding: 16px;
    .image-logo {
      max-width: 120px;
    }
    .empty-text {
      display: block;
      color: #666;
      padding-top: 16px;
    }
  }
}
</style>
